<template>
  <v-expansion-panels class="pa-0">
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t('forms.anchor') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-for="item in anchorValues" :key="`anker_${item.value}`">
          <locale-text-field
            v-model="item.translation"
            :label="$t('forms.anchorLabel', { value: item.value })"
            dense
          ></locale-text-field>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import localeTextField from '@/components/misc/localization-text-field.vue';

export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    minValue: {
      type: [Number, String],
      required: false,
      default: 0
    },

    maxValue: {
      type: [Number, String],
      required: false,
      default: 4
    }
  },

  data: () => ({
    anchorValues: []
  }),

  watch: {
    anchorValues: {
      handler() {
        this.$emit('input', this.anchorValues);
      },

      deep: true
    }
  },

  mounted() {
    if (this.value.length > 0) {
      this.anchorValues = this.value;
    } else {
      for (let i=0; i<=10; i++) {
        this.anchorValues.push({  
          value: i,
          translation: [
            {
              "languageKey": "de-DE",
              "translation": ""
            },
            {
              "languageKey": "en-US",
              "translation": ""
            }
          ]
        });
      }
    }
  },

  components: {
    localeTextField
  }
};
</script>
