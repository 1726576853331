<template>
  <v-textarea v-model="textInSelectedLanguage" ref="field" v-bind="$attrs">
    <template slot="append">
      <v-btn
        small
        class="mr-1"
        elevation="0"
        @click="setLanguage('de-DE')"
        :color="getColor('de-DE')"
      >
        <img src="@/assets/images/flags/de.png" />
      </v-btn>
      <v-btn
        small
        elevation="0"
        @click="setLanguage('en-US')"
        :color="getColor('en-US')"
      >
        <img src="@/assets/images/flags/en.png" />
      </v-btn>
    </template>
  </v-textarea>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    currentLanguageKey: {
      type: String,
      required: false,
      default: () => 'de-DE'
    }
  },

  data() {
    return {
      itemLanguageKey: 'de-DE',
      textInSelectedLanguage: ''
    };
  },

  mounted() {
    this.updateField();
    this.itemLanguageKey = this.currentLanguageKey;
  },

  watch: {
    textInSelectedLanguage(newValue) {
      var langItem = this.value.find(
        (x) => x.languageKey === this.itemLanguageKey
      );

      if (langItem) {
        langItem.translation = newValue;
      } else {
        this.value.push({
          languageKey: this.itemLanguageKey,
          translation: newValue
        });
      }
    },

    value: {
      handler() {
        this.updateField();
      },
      deep: true
    },

    currentLanguageKey() {
      this.updateField();
    }
  },

  methods: {
    setLanguage(lang) {
      this.itemLanguageKey = lang;
      this.updateField();
    },

    updateField() {
      if (!this.value) {
        this.textInSelectedLanguage = '';
        return;
      }

      const correctLanguage = this.value.find(
        (x) => x.languageKey === this.itemLanguageKey
      );
      
      this.textInSelectedLanguage = correctLanguage
        ? correctLanguage.translation
        : '';
    },

    focus() {
      this.$refs.field.focus();
    },

    getColor(requiredColor) {
      if (this.itemLanguageKey === requiredColor) {
        return 'secondary';
      } else {
        return 'default';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep button.v-btn {
  padding: 0 6px;
  min-width: 40px;
}
</style>
